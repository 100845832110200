import React, { useState, useRef, useEffect } from 'react';
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonImg,

  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { PindPlans } from './PlansModal'

const FriendsList =  () => (
  <IonList>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/300?u=b" />
      </IonAvatar>
      <IonLabel>
        <h2>G milli</h2>
        <p>Oh behave!!!</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=14" />
      </IonAvatar>
      <IonLabel>
        <h2>Huddy</h2>
        <p>Meow</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=41" />
      </IonAvatar>
      <IonLabel>
        <h2>AJ Kenny</h2>
        <p>The baddest of bitches</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src="https://i.pravatar.cc/400?img=9" />
      </IonAvatar>
      <IonLabel>
        <h2>T Sauce</h2>
        <p>Hot girl court is now in session</p>
      </IonLabel>
    </IonItem>
  </IonList>
)

export function PlansModalCard( {parentRef, isOpen, setIsOpen, plans} ) {
  const modal = useRef(null);
  const page = parentRef //useRef(null);

  const [presentingElement, setPresentingElement] = useState(null);

  useEffect(() => {
    console.log(page,page.current)
    setPresentingElement(page.current);
  }, []);

  function dismiss() {
    //setIsOpen(false)
    console.log('dismissd')
    modal.current?.dismiss();
  }

  const [appTitle, setAppTitle] = useState('NYC');
  const togglePlansMenu = () => {
    const inputPrompt = prompt("What's your homebase?", appTitle)
    if (inputPrompt) {
      setAppTitle(inputPrompt)
    }
  }

  const [activeTab, setActiveTab] = useState('friends');

  const handleSegmentChange = (event) => {
    setActiveTab(event.detail.value);
  };

  return (
    // <IonPage ref={page}>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>App</IonTitle>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent className="ion-padding">
    //     <IonButton id="open-modal" expand="block">
    //       Open
    //     </IonButton>
        <IonModal ref={modal} canDismiss={true}
        isOpen={isOpen}//
        onDidDismiss={() => setIsOpen(false)}
        //trigger="open-modal" 
        presentingElement={presentingElement}>
          <IonHeader>
            <IonToolbar>
              {/* <IonTitle>Friends</IonTitle>
              <a style={{pointerEvents: true}} onClick={() => setIsOpen(false)}>Close</a>
              <button onClick={() => console.log('dissy')}>parClose</button>
              <IonButton onClick={dismiss}>Close</IonButton>*/}
              <IonButtons>
                <IonButton onClick={togglePlansMenu}>{appTitle}</IonButton>
              </IonButtons> 
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons> 
              <IonSegment value={activeTab} onIonChange={handleSegmentChange}>
                <IonSegmentButton value="friends">
                  <IonLabel>Friends</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="favorites">
                  <IonLabel>Favorites</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {
              activeTab == 'friends' ? <FriendsList/> : <PindPlans plans={plans}/>
            }
          </IonContent>
        </IonModal>
    //   </IonContent>
    // </IonPage>
  );
}
