import React, { Component } from 'react';
import { StaticHeader } from './Header';
import { Divider } from './components/Divider';
import { ItineraryBody } from './ItineraryList';
//import SegmentAdder from './SegmentAdder';
import Select from "@kiwicom/orbit-components/lib/Select";

const TripBody = ( {trip, addlStyle} ) => {
  const body = {
    overflow: 'auto',
    display: 'flex', //need otherwise padding gets scrolled
  }

  return (
    <div style={{ ...body, ...addlStyle }} onClick={(e) => e.stopPropagation()}>
      <ItineraryBody trip={ trip }/>
    </div>
  );
}

export const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px', //'80vw',
  maxHeight: '100svh',
  //margin: '0em auto', //only needed to center if width isn't 100%
}

export const cardStyle = {
  background: 'white', //eventually make dynamic?
  height: '82svh',
  padding: '8px 0px 8px', //add bottom for top/bottom rounding
  borderRadius: '10px',
}

const TripSelector = ( props ) => {
  const [trip, setTrip] = React.useState("");

  let selOptions = []
  for (let idx = props.trips.length - 1; idx >= 0; idx--) {
    selOptions.push({
          label: props.trips[idx].planName,
          value: idx
        });
  }

  return (
    <Select
      value={trip}
      options={selOptions}
      onChange={(event) => {
          props.changeSelection(event.currentTarget.value);
          setTrip(event.currentTarget.value);
        }}
    />
  );
};

//Endpoints for backend
var url = '/plans'//
//var url = 'http://127.0.0.1:5000/sheetstrip' //local sheetstrip API

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: [{"planName":"","img":" ","planDetails":{"meta": " "}}],
      idx: 0,
    };
  }

  componentDidMount() {
      //query without trip_id gets all trips upfront, slower start but faster nav
      var query_url = url //`${url}?trip_id=${this.state.idx}`
      fetch(query_url)
      .then(d => d.json())
      .then(d => {
        this.setState({
          term: d.plans,
          idx: d.plans.length - 1
        })
      })
    }

  changeSelection = (index) => {
    if (this.state.term[index]) { //}.segments) {
      console.log('not fetching')
      this.setState(prevState => ({
        //term: term,
        idx: index
      }))
    }
    else {
      var query_url = `${url}?trip_id=${index}`//need to rethink endpoint
      console.log('fetching' + query_url)
      fetch(query_url)
      .then(d => d.json())
      .then(d => {
        this.setState(prevState => ({
          term: d.trip,
          idx: index
        }))
      })
    }
  }

  render() {
    const trips = this.state.term
    const idx =  this.state.idx
    return (
      <div>
      {trips &&
        <div className="App" style={{...baseStyle, ...cardStyle}}>
        {trips.length >  1 && 
          <TripSelector trips={trips} changeSelection={this.changeSelection}/> 
        }
          <div style={{padding: '12px 12px 0px'}}>
          <StaticHeader plan={trips[idx]}/>
          </div>
          <Divider/>
          <TripBody trip={trips[idx]}/>
        </div>
      }
      {/*<SegmentAdder/>*/}
      </div>
    );
  }
}
