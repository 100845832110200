
import Modal, {ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import Button from "@kiwicom/orbit-components/lib/Button";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import { FlightSegment } from './Segments';

export const PindPlans = ( {plans} ) => (
  <Itinerary>
    <div style={{colorScheme: 'light dark', backgroundColor: 'light-dark(white, #999999)'}}
    > 
      {
        plans
          .toReversed()
          .flatMap(obj => Object.values(obj.planDetails?.addlDetails?.pins || {}))
          .map((segment, i) => {
            console.log(segment)
            return segment && <FlightSegment key={i} item={segment}/>
          })
      }
    </div>
  </Itinerary>
)

export const PlansModal = ( {plans, setShowPlusModal} ) => {

  return (
    <Modal
      onClose={(e) => {
        setShowPlusModal(false);
        e.stopPropagation(); 
      }}
    >
      <ModalHeader
        title='Plans'
        //description='Likes from across all your plans live here :)'
      />
      <ModalSection>

      <PindPlans plans={plans}/>

      </ModalSection>
      <ModalFooter>
        <Button 
          fullWidth={true} 
          submit={true}
          //onClick={console.log('Share clicked')}
        >
        Share
        </Button>
      </ModalFooter>
    </Modal>
  );
};