const cutOutWidth='8px'
const bgColor = "var(--background-color)"
const leftCutout=`radial-gradient(circle at 0% 8px, ${bgColor} 0, ${bgColor} 8px, rgba(0, 0, 0, 0) 8px)`
const rightCutout=`radial-gradient(circle at 100% 8px, ${bgColor} 0, ${bgColor} 8px, rgba(0, 0, 0, 0) 8px)`

export const Divider = ( {marginStyle = '4px 0px'} ) => (
    <div style={{
                  margin: marginStyle,
                  backgroundImage: `${leftCutout},${rightCutout}`,
                  colorScheme: 'light dark',
              }}
    >
      <div style={{height: '8px'}}></div>
      <div style={{height: '0px', margin: '0px 8px', borderBottom: '1px dashed #ccc'}}></div>
      <div style={{height: '8px'}}></div>
    </div>
    );

export default Divider;