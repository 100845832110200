import moment from 'moment'
import { ShareButton } from './components/ShareButton';

const headingStyle = {
  textWrap: 'nowrap',
  //below were pulled out when removing https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css
  marginTop: '10px',
  marginBottom: '10px',
  lineHeight: '1.1',
  fontWeight: '500',
  fontFamily: '"SF Display", sans-serif',
  //https://stackoverflow.com/questions/32660748/how-to-use-apples-new-san-francisco-font-on-a-webpage
}

export const FormatDateShort = (dateString) => moment(dateString, "YYYY-MM-DD").format("MMM Do");

const HeaderText = ({ banner, meta, link}) => (
    <div className='header-container'>
      <h2 style={{...headingStyle, fontSize: '30px'}}>{banner}</h2>
      <h3 style={{...headingStyle, fontSize: '24px'}}>
      {meta + ' '}
        <ShareButton title="Let's Go" url={link}/>
      </h3>
    </div>
  );

export const headerRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  flexGrow: 1,
  flexShrink: 0,
  justifyContent: 'flex-start', //justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  rowGap: '12px',
  columnGap: '12px',

  maxHeight: '100px',
}

const GetListPlanLink = (plan) => {
  const queryParams = new URLSearchParams();
  queryParams.append("planName", plan.planName || plan.planDetails?.planName);
  queryParams.append("meta", plan.planDetails?.meta || FormatDateShort(plan.planDetails?.start_date));
  queryParams.append("img", plan.img || plan.planDetails?.img);
  queryParams.append("sheetId", plan.sheetId || plan.planDetails?.sheetId);
  const fullURL = new URL(`${window.location.origin}/list?${queryParams.toString()}`);
  return fullURL.href //URL does not allow relative paths
}


export const StaticHeader = ({ plan }) => (
    <div style={{ ...headerRowStyle }}>
    <img src={plan.img || plan.planDetails?.img} alt="" width="100" height="100"/>
    <HeaderText 
      banner={plan.planName || plan.planDetails?.planName} 
      meta={plan.planDetails?.meta || FormatDateShort(plan.planDetails?.start_date)} 
      link={plan.planDetails?.sheetId ? GetListPlanLink(plan) : plan.link}
    />
    </div>
);

export const CompactHeader = ({ plan }) => (
  <div style={{ ...headerRowStyle, marginBottom: '10px', }} className="header-container">
  <img src={plan.img || plan.planDetails?.img} alt="" width="32" height="32"/>
  <label>
    {plan.planName} 
  </label>
  <label style={{marginLeft: 'auto'}} >
    {plan.planDetails?.meta || FormatDateShort(plan.planDetails?.start_date)} 
  </label>
  </div>
);