import { StaticHeader, CompactHeader } from './Header';
import { Divider } from './components/Divider';
import { ItineraryBody }  from './ItineraryList';
import { ResBody } from './Segments';
import { SearchBody } from './Segments';
import { FlightSearchInputs } from './FlightSearchAdder';
import { ResSearchInputs } from './ResAdder';
import { HookForm } from './HookFormInputs';

function getDefaultBackground(id) {
  const defaultColors = ['#c12014','#f16d5e','#4bb491','#1482d2','#b2b3b2','#27AE60','#649ae1','#f0a164','#d2d2d2']
  return defaultColors[(id) % defaultColors.length]
}

function getLuminosity(hexColor) {
  const hex = hexColor.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate perceived brightness using W3C formula
  return Math.floor(0.299 * r + 0.587 * g + 0.114 * b);
}

const upsertPlanState = (plan, key) => (value) => {
  const updatedDetails = {...plan.planDetails, planState: {[key]: value}};

  plan.planUpdater && plan.planUpdater(updatedDetails)
};

export const usePlanState = (plan, key, defaultValue) => {
  plan.planDetails.planState?.[key] || upsertPlanState(plan, key)(defaultValue)

  return [
    plan.planDetails.planState?.[key] || defaultValue,
    upsertPlanState(plan, key)
  ];
};

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px',
  maxHeight: '100svh',
  color: 'var(--text-color)'
}

//note: style props are upserted
export const Plan = ( {trip, cardStyle, viewOptions, standalone=false} ) => {
  const planUpdater = trip.planUpdater
  const defaultHeader = standalone ? <StaticHeader plan={trip}/> : <CompactHeader plan={trip}/>

  const headers = {
    'input': <HookForm submitHandler={planUpdater} details={trip.planDetails} Inputs={FlightSearchInputs}/>,
    'search': <HookForm submitHandler={planUpdater} details={trip.planDetails} Inputs={FlightSearchInputs}/>,
    'res': <HookForm submitHandler={planUpdater} details={trip.planDetails} Inputs={ResSearchInputs}/>,
  }

  const segmentBodys = {
    'input': <SearchBody item={trip.planDetails} inputsUpdater={planUpdater}/>,
    'search': <SearchBody item={trip.planDetails} inputsUpdater={planUpdater}/>,
    'res': <ResBody item={trip.planDetails} inputsUpdater={planUpdater}/>
  }

  return (
      <div style={{...baseStyle, ...cardStyle}}>  
        <div style={{padding: '12px 12px 0px'}}>
        {viewOptions && !viewOptions['Mini Header'] 
          ? <StaticHeader plan={trip}/> 
          : headers[trip.planType] || defaultHeader
        }
        </div>
        <Divider {...(standalone ? {} : {marginStyle: '8px -2px'})}/>
        <div 
          onClick={(e) => e.stopPropagation()}
          style={{overflow: 'scroll', pointerEvents: planUpdater ? 'auto' : 'none'}}
        >
          {segmentBodys[trip.planType] || <ItineraryBody trip={trip}/>}
        </div>
      </div>
  );
}

export const PlanCard = ( {trip, height, viewOptions} ) => {

  const [bgColor, setBgColor] = usePlanState(trip, 'bgColor', getDefaultBackground(trip.planId))

  const bgBrightness = getLuminosity(bgColor);
  const monoColor = `rgb(${bgBrightness},${bgBrightness},${bgBrightness})`;

  const cardStyle = {
    colorScheme: bgBrightness / 255 < 0.75 ? 'dark' : 'light',
    background: viewOptions['Mono Theme'] ? monoColor : bgColor,
    border: '2px solid rgba(0,0,0, 0.25)',
    boxSizing: 'border-box',
    //boxShadow: '0px 3px 10px',
    borderRadius: '12px',
    maxHeight: height - 4, // alternative for marginBottom not working
  }

  return (
      <Plan 
        trip={trip}
        cardStyle={cardStyle}
        viewOptions={viewOptions}
      />
  );
}