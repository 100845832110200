import { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { FormInput, HookDateStyled, HookInputDatalist } from './HookFormInputs';
import InputField from "@kiwicom/orbit-components/lib/InputField";
import { headerRowStyle } from './Header';
import moment from 'moment'

const IATACodesDataList = ( {id, search_term} ) => {

    const [areaOptions, setAreaOptions] = useState({'NYC': 'New York'});
    useEffect(() => {
      if (search_term) {

        const url = `/v1/querylocationscodes?&search_term=${search_term}&result_limit=8` //360
        fetch(url)
        .then((response) => {
            return response.json()
              .then((json) => {
                if (response.ok) {
                  return Promise.resolve(json)
                }
                return Promise.reject(json)
              })
          })
        .then(setAreaOptions)
        .catch((ex) => {
            console.log(`Exception fetching: ${url}`, ex)
            // setAreaOptions([]) // clear areaOptions?
        });
      }
    }, [search_term]);

    return (
        <datalist id={id}>
        {
          areaOptions && Object.keys(areaOptions).map( (item, index) =>
              <option value={`${item} (${areaOptions[item]})`} key={index}>{areaOptions[item]}</option>
          )
        }
        </datalist>
    )

}

export const TogglerInput = ( { options = ["→", "⇄"], addlStyle = {flex: '0 0 16px', margin: '2px'} } ) => {
    const [isChecked, setIsChecked] = useState(false);
    const toggleChecked = () => setIsChecked(!isChecked)

    return (
      <label style={{WebkitUserSelect: 'none', ...addlStyle}}>
        <input disabled type="checkbox" onChange={toggleChecked} style={{display: 'none'}}/>
        {options[isChecked ? 1 : 0]}
      </label>
  );
}

export const FlightSearchInputs = ( {register, control, details, setValue} ) => {

  return (
  <>
  <div className="header-container" style={{...headerRowStyle}}>
    <div style={{display: 'inline-flex',flex: 'auto',alignItems: 'center',width: '40%',textAlign: 'center'}}>
      <HookInputDatalist name="cityCodeFrom" placeholder="From" defaultValue={details?.cityCodeFrom || "NYC"} 
                         register={register} control={control} setValue={setValue} DatalistComponent={IATACodesDataList}
      />
      <TogglerInput addlStyle={{flex: '0 0 32px', margin: '2px', fontSize: '23pt'}}/>
      <HookInputDatalist name="cityCodeTo" placeholder="To" defaultValue={details?.cityCodeTo} 
                         register={register} control={control} setValue={setValue} DatalistComponent={IATACodesDataList}
      />
    </div>

    <div style={{display: 'inline-flex',flex: 'auto', width: '25%'}}>
    <HookDateStyled 
      style={{marginLeft: 'auto'}}
      register={register}
      id="start_date"
      name="start_date"
      label="When?"
      //min={moment().format('YYYY-MM-DD')} // doesnt work on iOS
      defaultValue={details?.start_date
                    ? moment(details.start_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                    : '' /*moment().format('YYYY-MM-DD')*/}
    />
    </div>
  </div>
  <div className="header-container" style={{...headerRowStyle, minHeight: '40px'}}>
    <div style={{columnGap: '4px', display: 'inline-flex',flex: 'auto',alignItems: 'center',width: '40%'}}>
      <select name="num_passengers" style={{flex: '1 1 0'}} defaultValue={details?.num_passengers} {...register('num_passengers')}>
        <option value="1">1 guest</option>
        <option value="2">2 guests</option>
        <option value="3">3 guests</option>
        <option value="4">4 guests</option>
      </select>
      <select name="selected_cabins" style={{flex: '1 1 0'}} defaultValue={details?.selected_cabins} {...register('selected_cabins')}>
        <option value="M">Economy</option>
        <option value="W">Premium</option>
        <option value="C">Business</option>
        <option value="F">First class</option>
      </select>
      <select name="max_stops" style={{flex: '1 1 0'}} defaultValue={details?.max_stops} {...register('max_stops')}>
        <option value="0">Direct</option>
        <option value="1">1 stop</option>
        <option value="2">2 stops</option>
        <option value="20">∞ stops</option>
      </select>
      <select name="flexible" style={{flex: '1 1 0'}} defaultValue={details?.flexible} {...register('flexible')}>
        <option value="0">Exact date</option>
        <option value="1">+1 day</option>
        <option value="7">+1 week</option>
        <option value="30">+1 month</option>
        <option value="90">+3 months</option>
      </select>
    </div>
  </div>
  </>
  );
}

export const FlightSearchAdder = ( {register, control, details} ) => {
  const locationWatcher = useWatch({ control, name: 'cityCodeTo', defaultValue: "" });

  return (
    <>
        <IATACodesDataList id="areaOptions" search_term={locationWatcher[0]}/>
        
        <div style={{display: 'inline-flex',flex: 'auto',alignItems: 'center'}}>
        <InputField 
          label="Origin"
          placeholder="From"
          value={details?.cityCodeFrom}
          // list="areaOptions" // should be supported but not working
          name="origin"
          {...register('cityCodeFrom')}
        />
        <TogglerInput addlStyle={{fontSize: '23pt', color: 'white', margin: '20px 12px 0px'}}/>
        <FormInput 
          type="text"
          id="destination"
          placeholder="To"
          defaultValue={details?.cityCodeTo}

          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          list="areaOptions"
          label="Where to?"
          register={register}
          name="cityCodeTo"
        />
        </div>

        <FormInput 
          type="date"
          id="start_date"
          defaultValue={details?.start_date || moment().add(1, 'months').format('YYYY-MM-DD')}
              
          label="Takeoff Date"
          register={register}
          name="start_date"
        />
    </>
  );
};
