import { useEffect } from "react";
import { FormInput, HookDateStyled, HookInputDatalist } from './HookFormInputs';
import { headerRowStyle } from './Header';
import InputField from "@kiwicom/orbit-components/lib/InputField";
import InputGroup from "@kiwicom/orbit-components/lib/InputGroup";
import moment from 'moment'

const NeighborhoodsDataList = ( {id = 'areaOptions'} ) => {
  const areaOptions = [
    'Williamsburg',
    'Greenpoint',
    'East Village',
    'West Village',
    'Tribeca',
    'Midtown',
    'Uptown'
  ]
  return (
      <datalist id={id}>
      {
          areaOptions && areaOptions.map( (item, index) =>
          <option value={item} key={index}></option>
          )
      }
      </datalist>
  )  
}

export const ResSearchInputs = ( {register, control, details, setValue} ) => {

  return (
    <>
    <div className="header-container" style={{...headerRowStyle, minHeight: '40px'}}>
    <HookInputDatalist name="location" placeholder="Where?" defaultValue={details?.location} 
                         register={register} control={control} setValue={setValue} 
                         DatalistComponent={NeighborhoodsDataList} maxLength={100}
    />
    <HookDateStyled 
        style={{marginLeft: 'auto'}} 
        register={register}
        id="res_date"
        name="res_date"
        label="When?"
        defaultValue={details?.res_date
                      ? moment(details.res_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                      : '' }
      />
    </div>
    <div className="header-container" style={{...headerRowStyle}}>
      <select name="party_size" style={{flex: '1 1 0', height: '24px', maxWidth: '40%'}} 
              defaultValue={details?.party_size} {...register('party_size')}
      >
        <option value="1">1 guest</option>
        {[...Array(12).keys()].slice(1).map( (item, idx) =>
          <option key={idx} value={item+1}>{`${item+1} guests`}</option>
          )
        }
      </select>

      <div style={{marginLeft: 'auto'}} >
      <label>
      <input 
        type="time"
        name="time_start"
        defaultValue={details?.time_start || "19:00"}
        {...(register && register("time_start"))}
      />
      </label>
      <label>
      &nbsp;-&nbsp;
        <input 
        type="time"
        name="time_end"
        defaultValue={details?.time_end || "21:00"}
        {...(register && register("time_end"))}
      />
      
      </label>
      </div>
    </div>
    </>
  )
}

const AddlInputs = ( {register, setValue, location = ''} ) => {

  register("name");

  useEffect(() => {
    if (location.length > 0) {
        setValue("name", `${location} 🍽`);
    }
  }, [location]);

  return (
    <>
    </>
  );
};

export const ResAdder = ( {register, watch, setValue} ) => {
  const locationWatcher = watch('location')

  return (
    <>
        <FormInput 
              type="date"
              id="res_date"
              defaultValue={moment().format('YYYY-MM-DD')}
              
              label="Reservation Date"
              register={register}
              name="res_date"
        />
        <InputGroup flex={["2 2 6em", "2 2 6em"]} label="Times Between">
        <FormInput 
              type="time"
              id="time_start"
              //step="900"
              defaultValue="19:00:00"
              
              label="Earliest Time"
              register={register}
              name="time_start"
        />
        <FormInput 
              type="time"
              id="time_end"
              //step="900"
              defaultValue="21:00:00"
              
              label="Latest Time"
              register={register}
              name="time_end"
        />
        </InputGroup>
        <FormInput 
              type="number"
              id="party_size"
              min={1}
              defaultValue="2"
              
              label="Party Size"
              register={register}
              name="party_size"
        />
        <FormInput 
              type="text"
              id="location"

              label="Select An Area"
              register={register}
              name="location"
              list="areaOptions"
        />
        <NeighborhoodsDataList/>
        <InputField 
          label="Vibe"
          placeholder="vibe or cuisine"
          name="cuisine"
          {...register('cuisine')}
        />
        <AddlInputs register={register} setValue={setValue} location={locationWatcher}/>

    </>
  );
};