import React, { useEffect, useState } from "react";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import * as Segments from './Segments'

export const fetchSearch = ( url, statusSetter, resultsSetter ) => {

  statusSetter('Loading');
  fetch(url)
    .then((response) => {
        return response.json()
          .then((json) => {
            if (response.ok) {
              return Promise.resolve(json)
            }
            return Promise.reject(json)
          })
      })
    .then(resultsSetter)
    .then(()=>statusSetter('Success'))
    .catch((ex) => {
     console.log(`Exception fetching: ${url}`, ex)
     statusSetter(ex.error || ex.message)
    });
}

function toggleItemInArray(array, item) {
  return array.includes(item) ? array.filter(i => i !== item) : [...array, item]
}

const ItineraryListSegment = (props) => {
  const {type, ...childProps} = props;
  const Segment = Segments.segmentTypes[type];
  return <Segment {...childProps}/>; 
}

export const ItineraryList = ( { items, type, updatePins, pind_ids = [] } ) => {

  const styles = {
    //background: 'white', //remove for card look
    minHeight: '280px',
    width: '100%', // otherwise width is based on biggest inner element (nested link!)
    //margin: '1px auto',//overflow on right for PR... auto bad?
    overflowY: 'auto',
    overflowX: 'hidden',
    //lineHeight: '30px'
  }
  
  return (
    <div style={styles}>
    <Itinerary>
      {[...items]
        .sort((item) => !pind_ids.includes(item.id)) // sort pind to top of list
        .map( (item, index) =>
          <ItineraryListSegment
            key={item.id || index}
            type={item.type || type} 
            item={item} 
            pind={pind_ids.includes(item.id)}
            pinUpdater={updatePins && (() => updatePins(toggleItemInArray(pind_ids, item.id)))}
          />
          )
      }
    </Itinerary>
    </div>
  );
}
export default ItineraryList;

export const ItineraryBody = ( {trip} ) => {
  const searchParams = new URLSearchParams();
  searchParams.append("sheet_id",  trip?.planDetails?.sheetId);

  const endpoint = (trip.planType == 'expenses' ? 'sheetsexpenses' : 'sheetstrip')
  const url =  `/${endpoint}?${searchParams.toString()}`

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  useEffect(()=>{
    if ( trip?.segments?.length > 0 ) {
      setList({'trip': trip})
      return setStatus('Success')
    }
    if (!trip?.planDetails?.sheetId) {
      return setStatus('Link provided is not valid')
    }
    
    fetchSearch(url, setStatus, setList)

  }, [url]);

  return (
    <>
    {list.length == 0 
      ? <div style={{textAlign: 'center'}}><b>{status}</b></div>
      :<ItineraryList items={list.trip.segments}/>
    }
    </>
  );
}