import React from 'react';
import PropTypes from 'prop-types';

class CardStack extends React.Component {
    inputRef = React.createRef(null);

    getTopOffsets(cards, headerHeight, topHeight, selectedIndex = -1) {

        if (selectedIndex >= 0 || this.state.isStackHidden) {

            let scrollTop = this.inputRef.current ? this.inputRef.current.scrollTop : 0

            // adjust scrollTop to never be greater than implied scroll height (or less than 0)
            const maxScrollTop = Math.max(0, headerHeight * cards.length - this.props.height)
            if (scrollTop > maxScrollTop) scrollTop = maxScrollTop

            return cards.map((child, i) => {
                const topOffset = headerHeight * i
                const adjustedTopOffset = topOffset - scrollTop // compensate for offset that's been scrolled
                const translateUp = (topOffset > scrollTop) ? - adjustedTopOffset : 0
                const fullyHiddenHeight = this.props.height - adjustedTopOffset + this.props.footerHeight + 4 //shadow

                return (i > selectedIndex) ? fullyHiddenHeight : translateUp
            });
        }
        else {
            return cards.map((child, i) => 0);
        }
    }

    constructor (props) {
        super(props);
        const { children, selectedIdx } = props;
        const childrenLength = children.length || 1;

        if (childrenLength <= 1) throw new Error('CardStack must have at least two child Card components');

        this.state = {
            isStackHidden: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isStackHidden: false });
          }, 100); //BNL//500
    }

    handleCardClick (id) {
        if (this.props.selectedUpdater) {
            this.props.selectedUpdater((this.props.selectedIdx >= 0) ? -1 : id)
        }
    }

    renderCards () {
        // generating offsets should really only be done when # cards changes, ideally save state and add scroll dynamically
        const topOffsets = this.getTopOffsets(this.props.children, this.props.headerHeight, this.props.topHeight, this.props.selectedIdx)
        //let topOffsets = this.state.topOffsets

        const cloneCard = (child, i) =>
            React.cloneElement(child, {
                key: child.key || i,
                cardId: i,//child.planId,
                headerHeight: this.props.headerHeight,
                cardSelected: this.props.selectedIdx,
                height: this.props.height,
                topOffset: topOffsets[i],// ?? 75,
                onClick: this.handleCardClick.bind(this),
            });
        //console.log(this.props.children.map(cloneCard))
        //console.log(this.state.topOffsets)
        return this.props.children.map(cloneCard);
    }

    render () {
        //scrollContainerHeight needed to ensure scrollTop isn't lost when card is clicked
        const scrollContainerHeight = (this.props.children.length - 1) * this.props.headerHeight + this.props.height
        const stackStyles = {
            ...styles,
            height: this.props.height * 2 - this.props.headerHeight, // prevent scrolling past last header
            width: this.props.width,
            overflow: (this.props.selectedIdx >= 0) ? 'hidden' : 'scroll'
        };
        return (
            <ul ref={this.inputRef} style={stackStyles}>
                <div style={{minHeight: scrollContainerHeight}}>
                {this.renderCards()}
                </div>
            </ul>
        );
    }
}

const styles = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    //overflow: 'scroll', //body is hidden, so turn off hidden here
    overscrollBehavior: 'contain',
    listStyleType: 'none', //fixes offset?

    padding: 0,
    margin: 0,
};

CardStack.propTypes = {
    height: PropTypes.number,
    headerHeight: PropTypes.number,
    width: PropTypes.string, //w/ number throws warning on % or vw
    selectedIdx: PropTypes.number,
};

CardStack.defaultProps = {
    width: '100%',
    height: 650,
    //selectedIdx: -1,
    headerHeight: 112,
    topHeight: 0,
};

export default CardStack;