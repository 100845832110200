const centeredStyle = {
  textAlign: 'center',
  lineHeight: '40vh', //100vh to fully center
  color: 'white'
}

export const LandingPage = () => (
  <div style={{backgroundColor: 'black', height: '-webkit-fill-available'}}>
  <h1 style={centeredStyle}>
    COMING SOON
  </h1>
  <img 
    src={process.env.PUBLIC_URL + '/landing.gif' }
    width='200'
    style={{float: 'right'}}
  >
  </img>
  </div>

);