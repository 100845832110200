export const ImgButton = ( props ) => {
  var buttonStyles = {
    width: props.diameter,
    height: props.diameter,
    borderRadius: '100%',
    backgroundColor: 'var(--primary-color)',
    filter: window.isDarkMode() ? 'invert(100%)' : '', // 'invert(100%)',
    backgroundImage: `url(${props.imgURL})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    ...props.addlStyles
  }

  return (
    <div 
      style={buttonStyles}
      {...(props.onClick ? {onClick: props.onClick} : {})}
    >
      {props.children}
    </div>
  );
}

function getSelectedOptionsObj(optionsObj, selectedOptions) {
  const prevState = structuredClone(optionsObj) // deep copy to ensure no side effects
  for (const groupKey in prevState) {
    const groupValues = prevState[groupKey]
    for (const optionKey in groupValues) {
      if (selectedOptions.includes(optionKey)) {
        prevState[groupKey][optionKey] = true
      }
      else {
        prevState[groupKey][optionKey] = false
      }
    }
  }
  return prevState
}

export const GroupSelect = ( props ) => {
  const selectStyles = {
    width: props.diameter,
    height: props.diameter,
    position: 'relative',
    top: '-10px',
    right: '0px',
    outline: 'none',
    border: 'transparent',
    color: 'transparent',
    backgroundColor: 'transparent',
    WebkitAppearance: 'none',
  }

  function getMultiSelected(selOptions) {
    const optionsObjFlat = Object.assign({}, ...Object.values(selOptions))
    return Object.keys(optionsObjFlat).filter(key => optionsObjFlat[key] === true)
  }
  let optionIndex = 0;

  return (
    <select
      style={selectStyles} 
      name="options" 
      onChange={props.changeHandler}
      {...(props.multi 
        ? {multiple: true, value: getMultiSelected(props.selOptions)}
        : {value: props.placeholder})
      }
    >
      {props.placeholder && <option disabled hidden>{props.placeholder}</option>}
    {Object.keys(props.selOptions).map((groupKey, index) => {
      const groupValues = props.selOptions[groupKey]
      const groupOptions = props.multi ? Object.keys(groupValues) : groupValues
      // wrap each group in an OptGroup unless it's name is "options" (for ungrouped)
      if (groupKey != 'options') {
        return (
        <optgroup key={index} label={groupKey}>
          {groupOptions.map((item) => {
            const optionKey = optionIndex++
            return (
             <option key={optionKey} value={item}>
                {item}
             </option>
            );
          })}
        </optgroup>
      );
      } else {
          return groupOptions.map((option) => {
            const optionKey = optionIndex++
            return (
             <option key={optionKey} value={option}>
                {option}
             </option>
            );
          })
    }
    })}
    </select>
  );
}

export const MultiOptionsButton = ( props ) => (
  <ImgButton diameter={props.diameter} imgURL={process.env.PUBLIC_URL + '/list.svg'}>
    <GroupSelect multi={true} diameter={props.diameter} selOptions={props.selOptions} 
      changeHandler={(e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value)
        props.changeHandler(getSelectedOptionsObj(props.selOptions, selectedOptions))
      }}
    />
  </ImgButton>
)

export const ColorButton = ( props ) => (
  <input type="color" id="favcolor" name="favcolor" value={props.color || '#007aff'}
    style={{
      width: props.diameter,
      height: props.diameter,
      position: 'relative',
      //top: '-4px',
      /* padding: 0px; */
    }}
    onChange={(event) => {
      props.changeHandler(event.currentTarget.value)
    }}
  />
)

export const ListButton = ( props ) => (
  <ImgButton diameter={props.diameter} imgURL={process.env.PUBLIC_URL + '/list.svg'}>
    <GroupSelect diameter={props.diameter} selOptions={props.selOptions}
      changeHandler={(event) => props.changeHandler(event.currentTarget.value)}
    />
  </ImgButton>
)

export const SelectNewButton = ( props ) => (
  <ImgButton diameter={props.diameter} imgURL={process.env.PUBLIC_URL + '/plus.svg'} addlStyles={props.addlStyles}>
    <GroupSelect placeholder={props.placeholder} diameter={props.diameter} selOptions={props.selOptions} 
    changeHandler={(event) => {
      props.changeHandler(event.currentTarget.value)
      event.currentTarget.options[0].selected = true // reset to disabled / tooltip option
    }}
    />
  </ImgButton>
)

export const DeleteButton = ( props ) => (
  <ImgButton 
    diameter={props.diameter} 
    imgURL={process.env.PUBLIC_URL + '/plus.svg'} 
    onClick={props.onClick}
    addlStyles={{transform: 'rotate(45deg)'}}
  />
)