import React from 'react';
import { useForm } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { FormatDateShort, headerRowStyle } from './Header';

// just like an input element, except w/ additional label prop
export const FormInput = ( props ) => {
  const {label, register, ...inputProps} = props;

  return (
    <label className="InputField__Field-sc-19cgljf-0 jzgoBR">
    <span className="FormLabel-sc-14kkg44-2 kVDPSv">
      <span>{label}</span>
    </span>
    <div className="InputField__InputContainer-sc-19cgljf-2 gjMAnv">
      <input 
        {...inputProps}
        {...(register && register(props.name))}
        className="InputField__Input-sc-19cgljf-6 ihLOjl"
      />
      <div className="InputField__FakeInput-sc-19cgljf-1 isLhOw"></div>
    </div>
  </label>
  );
};

export const HookInputDatalist = ( {name, placeholder, defaultValue, register, control, setValue, 
                                    DatalistComponent, maxLength = 3} ) => {
  const valWatcher = useWatch({ control, name: name, defaultValue: "" });
  const listName = `${name}-options`
  return (
    <>
      <DatalistComponent id={listName} search_term={valWatcher}/>
      
      <input type="text" id={name} name={name} placeholder={placeholder} defaultValue={defaultValue}
        list={listName} autoComplete="off" autoCorrect="off" spellCheck="false"
        {...register(name, {
          onBlur: (e) => setValue(name, e.target.value.slice(0,maxLength))
        })}
        style={{maxWidth: '50vw'}} //fieldSizing: 'content'}} // not available in safari yet
      />
    </>
  );
}

export const HookDateStyled = ( props ) => {
  const {label, register, ...inputProps} = props;
  return (
  <input 
    type="date" 
    className="styledDate"
    {...inputProps}
    {...(register && register(props.name))}
    data-display-value={props.defaultValue ? FormatDateShort(props.defaultValue) : label}
    onChange={(e) => {
      const formattedDate = FormatDateShort(e.target.value)
      e.target.dataset.displayValue = formattedDate == 'Invalid date' ? label : formattedDate
    }}
    //onFocus={(e) => { e.target.type = 'date'; }} //integrate ref /hook-form?
    //placeholder={label}  // type=date does not show placeholder
  />
  );
}

export const HookForm = ( {submitHandler, details, Inputs} ) => {
  const { register, control, handleSubmit, setValue } = useForm();

  return (
    <form 
      noValidate 
      onSubmit={handleSubmit(submitHandler)}
      className="active" 
      {...(!submitHandler ? {inert: 'true', className: "inert" } : {})}
    >
      <Inputs details={details} register={register} control={control} setValue={setValue}/>
      <div style={{ ...headerRowStyle, justifyContent: 'center', colorScheme: 'none' }}>
      <button style={{ margin: '2px 0px', width: '64px', height: '28px' }} type="submit">Search</button>
      </div>
    </form>
  );
};