export const ShareButton = ( {url, title, diameter = 20} ) => {

  const svgStyle = {
    stroke: 'currentColor',
    strokeWidth: 5,
    strokeDasharray: 'none',
    strokeLinecap: 'butt', //stroke-linecap="round"
    strokeLinejoin: 'miter',
    strokeMiterlimit: 10,
    fill: 'rgb(0,0,0)',
    fillRule: 'nonzero',
    opacity: 1
  }

  return (
    <a 
      style={{color: 'inherit', verticalAlign: 'middle', lineHeight: 0}}
      onClick={
        (e) => {
          if (navigator.share) {
            navigator.share({
              title: title, //doesn't show if page has a title or og:title
              //text: `Let's go to ${banner}`, //only shows on share menu, not links
              url: url,
            })
            .catch((error) => console.log('Error sharing', error));
          } 
          e.stopPropagation()
        }
      }
    >
      <svg width={diameter} height={diameter} viewBox="0 0 256 256">
        <defs>
        </defs>
        <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
            <path style={svgStyle} transform=" matrix(1 0 0 1 0 0) " d="M 30.459 41.331 c -0.284 0 -0.563 -0.121 -0.758 -0.348 L 0.242 6.764 c -0.259 -0.301 -0.315 -0.727 -0.144 -1.085 C 0.27 5.321 0.624 5.09 1.035 5.112 l 88 3.092 c 0.452 0.016 0.838 0.333 0.939 0.775 c 0.102 0.441 -0.105 0.895 -0.505 1.107 L 30.928 41.214 C 30.779 41.293 30.619 41.331 30.459 41.331 z M 3.249 7.191 l 27.446 31.881 l 54.542 -29 L 3.249 7.191 z"/>
            <path style={svgStyle} transform=" matrix(1 0 0 1 0 0) " d="M 42.354 84.889 c -0.048 0 -0.097 -0.004 -0.146 -0.011 c -0.393 -0.058 -0.714 -0.343 -0.819 -0.726 L 29.494 40.595 c -0.123 -0.451 0.083 -0.927 0.496 -1.146 L 88.53 8.321 c 0.399 -0.213 0.893 -0.13 1.201 0.201 s 0.356 0.828 0.117 1.212 L 43.203 84.418 C 43.018 84.714 42.696 84.889 42.354 84.889 z M 31.634 40.839 l 11.083 40.582 l 43.459 -69.583 L 31.634 40.839 z"/>
        </g>
      </svg>
    </a>
  );
}